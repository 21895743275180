import React from 'react';
import { basePath, baseURL } from '../../routes';
import { NavLink } from "react-router-dom";
import './footer.scss';

const Footer = () => {
  return (
    <footer className='mt-auto'>
      <div className='footer pt-5 pb-10 sm:pt-16 sm:pb-20'>
        <div className='container'>
        <div className='block xl:hidden flex flex-col w-56 sm:w-fit w-full'>
            <img className='w-32 sm:w-36' src={`${basePath}images/logo-lightMinimal.svg`} alt="blukite logo" />
          </div>
        <div className='flex flex-col xl:flex-row justify-between mt-10 xl:mt-0'>
          <div className='hidden xl:block flex flex-col w-56 sm:w-fit w-full'>
            <img className='w-28 sm:w-36' src={`${basePath}images/logo-lightMinimal.svg`} alt="blukite logo" />
          </div>
          <div className='text-white font-light flex flex-col gap-5 sm:gap-0 sm:flex-row w-full xl:w-6/12 justify-between'>
            <div className='flex flex-col gap-0 sm:gap-2'>
              <p className='uppercase font-normal'>Engage with BKF</p>
              <NavLink className="footer__link mt-3 sm:mt-0" to={`${baseURL}/customised_modular_services`}>Discover Our Services</NavLink>
                  <NavLink className="footer__link" to={`${baseURL}/`}>Knowledge Hub</NavLink>

              <NavLink className="footer__link" to={`${baseURL}/diversity_and_inclusion`}>Diversity & Inclusion</NavLink>
              <NavLink className="footer__link" to={`${baseURL}/compliance_and_ethics`}>Compliance and Ethics</NavLink>
                  <NavLink className="footer__link" to={`${baseURL}/esg`}>ESG Framework </NavLink>
              <NavLink className="footer__link" to={`${baseURL}/white_label_consumer_app`}>White Label Payment App</NavLink>
            </div>
            <div className='flex flex-col gap-0 sm:gap-2'>
              <p className='uppercase font-normal'>Tailored Services</p>
              <NavLink className="footer__link mt-3 sm:mt-0" to={`${baseURL}/tailored_services_banks`}>For Banks</NavLink>
              <NavLink className="footer__link" to={`${baseURL}/strategic_technology_integration_for_building_societies`}>For Building Society’s</NavLink>
              <NavLink className="footer__link" to={`${baseURL}/growth_and_innovation_for_credit_unions`}>For Credit Unions</NavLink>
              <NavLink className="footer__link" to={`${baseURL}/fin_techs_credit_solutions`}>For FinTechs</NavLink>
              <NavLink className="footer__link" to={`${baseURL}/liquidity_providers_credit_solutions`}>For Underwriters</NavLink>
              <NavLink className="footer__link" to={`${baseURL}/tailored_services_retailers`}>For Retailers</NavLink>
            </div>
            <div className='flex flex-col gap-0 sm:gap-2'>
              <p className='uppercase font-normal'>Policies</p>
              <NavLink className="footer__link mt-3 sm:mt-0" to={`${baseURL}/terms_of_use`}>Terms of Use</NavLink>
              <NavLink className="footer__link" to={`${baseURL}/privacy_policy`}>Privacy Policy</NavLink>
              <NavLink className="footer__link" to={`${baseURL}/cookie_policy`}>Cookie Policy</NavLink>
              <NavLink className="footer__link font-normal" to={`${baseURL}/disclaimer`}>DISCLAIMER</NavLink>
            </div>
          </div>
          {/* <div className='flex gap-5 sm:mt-0 mt-5 sm:mx-0 mx-auto'> */}
          <div className='mt-10 xl:mt-0'>
            <p className='uppercase font-normal'>Follow us on</p>
            <div className='flex gap-4 mt-4'>
              <a href='https://www.facebook.com/profile.php?id=61558973578857' target='_blank' className='footer__socialIcon'>
                <img loading='lazy' width="40" height="40" className='w-10' src={`${basePath}images/ico/facebook.svg`} alt="facebook" />
              </a>
              <a href='https://www.linkedin.com/company/blukite-net/?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3BKEoIgVqmQ3WfxeIhpuiKxA%3D%3D' target='_blank' className='footer__socialIcon'>
                <img loading='lazy' width="40" height="40" className='w-10' src={`${basePath}images/ico/linkedin.svg`} alt="linkedin" />
              </a>
              <a href='https://twitter.com/BlukiteFinance' target='_blank' className='footer__socialIcon'>
                <img loading='lazy' width="40" height="40" className='w-10' src={`${basePath}images/ico/twitter.svg`} alt="twitter" />
              </a>
              <a href='https://www.instagram.com/blukite.finance?igsh=OTJ2cXN6NzVzM3o4&utm_source=qr' target='_blank' className='footer__socialIcon'>
                <img loading='lazy' width="40" height="40" className='w-10' src={`${basePath}images/ico/instagram.svg`} alt="instagram" />
              </a>
            </div>

            <NavLink className="footer__link uppercase font-normal mt-7 block" to={`${baseURL}/contact_us`}>CONTACT US</NavLink>

          </div>
        </div>
        </div>
      </div>
    </footer>

  )
}

export default Footer