import React, { useState, useEffect, Suspense, useRef } from 'react';
import './App.scss';
import './index.css';

// import './pages/companyOverview/companyOverview.scss';
// import './pages/tailoredServices/tailoredServices.scss';

import { connect } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { routes } from './routes';
import Menu from './components/menu/menu';
import Popup from './components/popup/popup'
import Footer from './components/footer/footer';
import SpeakerController from './components/speakerController/speakerController';
import { ConfigProvider, Tooltip } from 'antd';
import { basePath, baseURL } from './routes';
import { PlainButton } from './components/buttons/buttons';
import { openPopup } from './actions/popup';
import { setEaMode } from './actions/ea';
import Loader from './components/loader/loader';
import AOS from 'aos';
import Helmet from 'react-helmet';

import 'aos/dist/aos.css';

// import { trackButtonClick } from './utils/trackButtonClick';

const mapStateToProps = state => ({
  ...state.ea,
});

const mapDispatchToProps = dispatch => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  setEaMode: (payload) => {
    return dispatch(setEaMode(payload));
  }
})

function App({ eaMode, zoom, openPopup, setEaMode }) {
  const wrapperRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  useEffect(() => {
    wrapperRef.current.style.zoom = zoom;
  }, [zoom]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (pathname === '/book_consultation') {
      openPopup({ currentPopup: 'BookConsultation' });
    }
  }, [pathname]);

  useEffect(() => {
    let eaMode = localStorage.getItem("eaMode");
    if (!eaMode) return
    if (eaMode === 'true') {
      setEaMode({ eaMode: true })
    } else {
      setEaMode({ eaMode: false })
    }
  }, [])


  const [mobile, setMobile] = useState(window.innerWidth <= 500);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);


  return (
    <div className="App" ref={wrapperRef}>
      <Helmet>
        <meta name="description" content="BKF Systems provides an expansive ecosystem tailored for progressive credit providers. Our flagship BluSys® Core Solution anchors a comprehensive suite of services, empowering banks, credit unions, and lending institutions to meet and exceed the expectations of today's digital customers with innovative, integrated financial solutions." />
        <meta name="keywords" content="finance, support, services, BKF" />
        <meta name="author" content="BKF Systems" />
        <meta property="og:title" content="BKF Systems transforms credit with advanced services" />
        <meta property="og:description" content="BKF Systems provides an expansive ecosystem tailored for progressive credit providers. Our flagship BluSys® Core Solution anchors a comprehensive suite of services, empowering banks, credit unions, and lending institutions to meet and exceed the expectations of today's digital customers with innovative, integrated financial solutions." />
        <meta property="og:image" content={`https://blukite.finance/images/bkfWebsitePreview.jpg`} />
        <meta property="og:url" content={`https://blukite.finance`} />
      </Helmet>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#36a9e0',
            // borderRadius: 2,
            colorBgContainer: '#36a9e0',
            fontFamily: 'Roboto Condensed, sans-serif',
          },
        }}
      >
        {/* <Suspense> */}
          <Menu />
          <Popup />
          <Routes>
            {routes.map((x) => (
              <Route key={x.path} path={x.path} 
              // element={x.element} 
              element={
                <React.Suspense fallback={<Loader/>}>{x.element}</React.Suspense>
              }
              
              />
            ))}
          </Routes>
          <div tabIndex={0} role="button" className='bookConsultationBtn' aria-label="Book Consultation" onClick={() => {
            openPopup({ currentPopup: 'BookConsultation' });
            // trackButtonClick('Book consultation')
          }
          } onKeyDown={(e) => e.key === 'Enter' && openPopup({ currentPopup: 'BookConsultation' })}>
            <Tooltip placement="left" title="Connect with BKF Systems">
              <object className='pointer-events-none' data={`${basePath}images/ico/envelope_sideOption.svg`} aria-label="Envelope icon for book consultation button"></object>
            </Tooltip>
          </div>

          {!mobile &&
            <SpeakerController />
          }
          <Footer />

          <Loader />
        {/* </Suspense> */}
      </ConfigProvider>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);